import React, { useState } from "react"
import { FaTimes } from "react-icons/fa"

import * as InfoBannerStyles from "./infoBanner.module.css"

// TODO Show info banner only to users NOT from Europe
// TODO use location and browser locale?
const InfoBanner = ({
  children,
  className = "",
  Icon = null,
  dismissable = false,
}) => {
  const [show, setVisibility] = useState(true)

  return (
    <>
      {show && (
        <div
          dismissable={"" + dismissable}
          aria-live="polite"
          className={[InfoBannerStyles.infoBannerWrapper, className].join(" ")}
        >
          {dismissable && (
            <button
              className={InfoBannerStyles.closeButton}
              onClick={setVisibility.bind(this, false)}
              title="Dismiss information banner"
              aria-label="Dismiss information banner"
            >
              <FaTimes />
            </button>
          )}

          {/* {!!Icon ? <Icon className={InfoBannerStyles.infoIcon} /> : <FaInfo className={InfoBannerStyles.infoIcon} />} */}

          <p className={InfoBannerStyles.infoText}>{children}</p>
        </div>
      )}
    </>
  )
}

export default InfoBanner
