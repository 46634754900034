import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { FaHourglassHalf } from 'react-icons/fa'

import { LanguageContext } from '../../utils/LanguageContext'
import InfoBanner from '../infoBanner'

const English = <>Wow! Thank you so much for your order! We are a Slow Fashion company, we work ethically and its process is almost handmade. <strong>When orders are high it may take a little longer</strong>. If you have any questions please <Link to="/contact/">contact us</Link>, we will be more than happy to help you.</>

const Spanish = <>¡Guauu! ¡Muchas gracias por tu pedido! Somos una empresa Slow Fashion, trabajamos de forma ética y su proceso es casi artesanal. <strong>Cuando los pedidos son elevados pueden tardar un poco más de tiempo</strong>. Si tienes alguna duda, <Link to="/contact/">escríbenos</Link>. Estaremos encantados de poder ayudarte.</>

const QuantityWarning = () => {
  const { language } = useContext(LanguageContext)
  
  return (
    <InfoBanner dismissable Icon={FaHourglassHalf}>
      {language === "Spanish" ? Spanish : English}
    </InfoBanner>
  )
}

export default QuantityWarning